import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

const Hero: React.FC = () => {
    return (
      <section className="flex flex-col items-center justify-center min-h-screen bg-black text-white px-4 py-20">
        <h1 className="text-5xl md:text-7xl font-bold mb-6 text-center">
          Find cash flowing real estate investments
        </h1>
        <p className="text-xl md:text-2xl mb-8 text-center text-zinc-400 max-w-3xl">
          Meet the simple app to find your next investment property. Input search criteria and quickly analyze potential deals. Don't waste hours and hours of time on Zillow and Redfin.
        </p>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full max-w-sm sm:max-w-md">
          <Link to="/prop-search" className="w-full">
            <Button size="lg" className="px-8 bg-blue-600 hover:bg-blue-700 text-white w-full">
              Start Searching
            </Button>
          </Link>
          <a href="https://buy.stripe.com/00g5mQ5Rt0end3O5kk" className="w-full">
            <Button size="lg" variant="outline" className="px-8 text-black border-zinc-700 hover:bg-zinc-200 w-full">
              Purchase Now →
            </Button>
          </a>
        </div>
        <div className="mt-16 w-full max-w-4xl">
          <div className="relative overflow-hidden rounded-lg shadow-lg border border-blue-500 transition-all duration-300 ease-in-out hover:scale-105">
            <img 
              src="/propFinder-demoimage.png" 
              alt="Real estate investment app interface" 
              className="w-full transition-transform duration-300 ease-in-out hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-20"></div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Hero;
