import React, { useState, useEffect } from 'react';
import Navbar from '../components/landing/Navbar';
import FilterOptions from '../components/search/FilterOptions';
import PropertyCards from '../components/search/PropertyCards';
import { Button } from '../components/ui/button';
import SearchBar from '../components/search/SearchBar';
import Footer from '../components/landing/Footer';
import SubscriptionPopup from '../components/search/SubscriptionPopup';
import { supabase } from '../lib/supabaseClient';

interface SearchParams {
  city: string;
  page: number;
  minPrice?: number;
  maxPrice?: number;
  minCashFlow?: number;
}

interface Property {
  id: string;
  address: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  yearBuilt: number;
  imageUrl: string;
  zestimate: number;
  rentZestimate: number;
  livingArea: number;
  imgSrc: string;
  estimatedMortgage: number;
  detailUrl: string;
}

interface SearchResponse {
  results: Property[];
  pagination: {
    currentPage: number;
    totalPages: number;
    totalResultCount: number;
  };
}

export default function PropSearch() {
    const [searchParams, setSearchParams] = useState<SearchParams>({
      city: '',
      page: 1,
    });
    const [results, setResults] = useState<Property[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pagination, setPagination] = useState({
      currentPage: 1,
      totalPages: 1,
      totalResultCount: 0,
    });
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState<boolean>(false);

    useEffect(() => {
      checkSubscriptionStatus();
    }, []);

    const checkSubscriptionStatus = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('has_paid')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error checking subscription status:', error);
          return;
        }

        setIsSubscribed(data?.has_paid || false);
      }
    };

    const handleSearch = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!isSubscribed) {
        setShowSubscriptionPopup(true);
        return;
      }
      await fetchProperties(searchParams);
    };

    const handleFilterChange = (filters: {
      minPrice?: number;
      maxPrice?: number;
      minCashFlow?: number;
    }) => {
      if (Object.keys(filters).length === 0) {
        // Reset all filters
        setSearchParams((prevParams) => ({
          ...prevParams,
          minPrice: undefined,
          maxPrice: undefined,
          minCashFlow: undefined,
          page: 1,
        }));
      } else {
        // Apply new filters
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...filters,
          page: 1,
        }));
      }
    };

    const handlePageChange = (newPage: number) => {
      setSearchParams({ ...searchParams, page: newPage });
      fetchProperties({ ...searchParams, page: newPage });
    };

    const fetchProperties = async (params: SearchParams) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch('https://silent-pine-97d5.max-luckan.workers.dev/api/zillow-properties', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch properties: ${response.status} ${response.statusText}`);
        }

        const data: SearchResponse = await response.json();
        setResults(data.results);
        setPagination({
          currentPage: data.pagination.currentPage,
          totalPages: data.pagination.totalPages,
          totalResultCount: data.pagination.totalResultCount,
        });
      } catch (error) {
        console.error('Error fetching properties:', error);
        setError(`An error occurred while fetching properties: ${(error as Error).message}`);
      } finally {
        setIsLoading(false);
      }
    };
    return (
      <div className="min-h-screen bg-black text-white">
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6 text-center">Property Search</h1>
          <div className="flex flex-col items-center mb-6">
            <div className="w-full max-w-3xl mb-4">
              <SearchBar 
                onSearch={(city) => setSearchParams({ ...searchParams, city, page: 1 })}
                onSubmit={handleSearch}
                isLoading={isLoading}
              />
            </div>
            <div className="w-full max-w-3xl">
              <FilterOptions onFilterChange={handleFilterChange} />
            </div>
          </div>
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
          <PropertyCards properties={results} />
          {pagination && (
            <div className="flex justify-center mt-6">
              <Button
                onClick={() => handlePageChange(pagination.currentPage - 1)}
                disabled={pagination.currentPage === 1}
                className="mr-2 bg-zinc-800 hover:bg-zinc-700"
              >
                Previous
              </Button>
              <span className="mx-4 self-center">
                Page {pagination.currentPage} of {pagination.totalPages}
              </span>
              <Button
                onClick={() => handlePageChange(pagination.currentPage + 1)}
                disabled={pagination.currentPage === pagination.totalPages}
                className="ml-2 bg-zinc-800 hover:bg-zinc-700"
              >
                Next
              </Button>
            </div>
          )}
          {showSubscriptionPopup && (
            <SubscriptionPopup onClose={() => setShowSubscriptionPopup(false)} />
          )}
        </div>
        <Footer />
      </div>
    );
}