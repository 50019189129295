import React from 'react';

interface Property {
  address: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  livingArea: number;
  zestimate: number;
  rentZestimate: number;
  imgSrc: string;
  estimatedMortgage: number;
  detailUrl: string;
}

interface PropertyCardProps {
  property: Property;
}

function PropertyCard({ property }: PropertyCardProps) {
  const cashFlow = property.rentZestimate - property.estimatedMortgage;
  const cashFlowColor = cashFlow >= 0 ? 'text-green-500' : 'text-red-500';

  return (
    <div className="bg-zinc-800 rounded-lg shadow-md overflow-hidden">
      <img src={property.imgSrc} alt={property.address} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="font-bold text-lg mb-2 text-white">{property.address}</h3>
        <p className="text-zinc-300 mb-2">${property.price.toLocaleString()}</p>
        <p className="text-sm text-zinc-400 mb-1">{property.bedrooms} beds • {property.bathrooms} baths • {property.livingArea} sqft</p>
        {property.zestimate > 0 && (
          <p className="text-sm text-zinc-400 mb-1">Zestimate: ${property.zestimate.toLocaleString()}</p>
        )}
        {property.rentZestimate > 0 && (
          <p className="text-sm text-zinc-400 mb-1">Rent Zestimate: ${property.rentZestimate.toLocaleString()}/mo</p>
        )}
        <p className="text-sm text-zinc-400 mb-1">Est. Mortgage: ${property.estimatedMortgage.toLocaleString()}/mo</p>
        <p className={`text-sm mb-2 ${cashFlowColor}`}>Est. Cash Flow: ${cashFlow.toLocaleString()}/mo</p>
        <a href={property.detailUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">View on Zillow</a>
      </div>
    </div>
  );
}

interface PropertyCardsProps {
  properties: Property[];
}

export default function PropertyCards({ properties }: PropertyCardsProps) {
  if (!properties || properties.length === 0) {
    return <div className="text-center py-4 text-zinc-400">No properties found.</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
      {properties.map((property, index) => (
        <PropertyCard key={index} property={property} />
      ))}
    </div>
  );
}