import React from 'react';
import Navbar from '../components/landing/Navbar';
import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import Pricing from '../components/landing/Pricing';
import Signup from '../components/landing/Signup';
import Footer from '../components/landing/Footer';
import Accordion from '../components/landing/Accordion';

const LandingPage: React.FC = () => {
  const faqItems = [
    {
      question: "Why is this app not free?",
      answer: "This app costs money to run and to get the data, specifically the AI models and the data to train them. I would have loved to have made it free, but I think a one-time payment for lifetime access is fair.",
    },
    {
      question: "What features are included?",
      answer: "You get lifetime access to all features, including property analysis tools, email support, and more. You also get all new features as I am working on adding them ASAP.",
    },
    {
      question: "How does it work?",
      answer: "The idea is to make deal searching extremely simple and not more complicated than it has to be. It works like this: You enter a city and state, and your price and desired cash-flow filters, and get a quick list of properties that match your criteria. That's it. No more back-and-forth with agents, no more spending hours upon hours searching for the right property. It's a work in progress, but it's already live and working.",
    }
  ];

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <Hero />
      <Features />
      <Pricing />
      <Accordion items={faqItems} />
      <Signup />
      <Footer />
    </div>
  );
};

export default LandingPage;
