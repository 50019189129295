import React, { useState } from 'react';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Check, RotateCcw } from 'lucide-react';

interface FilterOptionsProps {
  onFilterChange: (filters: {
    minPrice?: number;
    maxPrice?: number;
    minCashFlow?: number;
  }) => void;
}

export default function FilterOptions({ onFilterChange }: FilterOptionsProps) {
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    minCashFlow: '',
  });
  const [filtersApplied, setFiltersApplied] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setFiltersApplied(false);
  };

  const applyFilters = () => {
    const numericFilters = Object.fromEntries(
      Object.entries(filters).map(([key, val]) => [key, val ? parseInt(val) : undefined])
    );
    onFilterChange(numericFilters);
    setFiltersApplied(true);
  };

  const resetFilters = () => {
    const resetFilters = {
      minPrice: '',
      maxPrice: '',
      minCashFlow: '',
    };
    setFilters(resetFilters);
    onFilterChange({}); // Immediately apply empty filters
    setFiltersApplied(false);
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div className="flex flex-col">
          <Input
            type="number"
            name="minPrice"
            placeholder="Min Price"
            value={filters.minPrice}
            onChange={handleInputChange}
            className="bg-zinc-800 text-white border-zinc-700 w-full"
          />
          <label className="text-xs text-zinc-400 mt-1">Min Price</label>
        </div>
        <div className="flex flex-col">
          <Input
            type="number"
            name="maxPrice"
            placeholder="Max Price"
            value={filters.maxPrice}
            onChange={handleInputChange}
            className="bg-zinc-800 text-white border-zinc-700 w-full"
          />
          <label className="text-xs text-zinc-400 mt-1">Max Price</label>
        </div>
        <div className="flex flex-col">
          <Input
            type="number"
            name="minCashFlow"
            placeholder="Min Cash Flow"
            value={filters.minCashFlow}
            onChange={handleInputChange}
            className="bg-zinc-800 text-white border-zinc-700 w-full"
          />
          <label className="text-xs text-zinc-400 mt-1">Min Cash Flow</label>
        </div>
      </div>
      <div className="flex justify-center gap-4">
        <Button 
          onClick={applyFilters} 
          className={`${filtersApplied ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
        >
          {filtersApplied ? (
            <>
              <Check className="mr-2 h-4 w-4" /> Applied
            </>
          ) : (
            'Apply Filters'
          )}
        </Button>
        <Button 
          onClick={resetFilters} 
          className="bg-zinc-700 hover:bg-zinc-600 text-white"
        >
          <RotateCcw className="mr-2 h-4 w-4" /> Reset
        </Button>
      </div>
    </div>
  );
}