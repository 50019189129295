import React from 'react';
import { Button } from '../ui/button';
import { X } from 'lucide-react';

interface SubscriptionPopupProps {
  onClose: () => void;
}

const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-zinc-800 p-8 rounded-lg max-w-md w-full relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-zinc-400 hover:text-white">
          <X size={24} />
        </button>
        <h2 className="text-3xl font-bold mb-4 text-white">Unlock Full Access</h2>
        <p className="text-zinc-300 mb-6">
          Get unlimited property searches and detailed analysis with our premium subscription.
        </p>
        <ul className="list-disc list-inside text-zinc-300 mb-6">
          <li>Unlimited property searches</li>
          <li>Detailed cash flow analysis</li>
          <li>Exclusive market insights</li>
          <li>Priority customer support</li>
        </ul>
        <a
          href="https://buy.stripe.com/00g5mQ5Rt0end3O5kk"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-full"
        >
          <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 text-lg">
            Purchase Now
          </Button>
        </a>
      </div>
    </div>
  );
};

export default SubscriptionPopup;