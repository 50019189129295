import React, { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabaseClient';
import { Input } from '../ui/input';
import { Button } from '../ui/button';

interface SearchBarProps {
  onSearch: (city: string) => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
}

export default function SearchBar({ onSearch, onSubmit, isLoading }: SearchBarProps) {
  const [city, setCity] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (city.length >= 3) {
      fetchSuggestions(city);
    } else {
      setSuggestions([]);
    }
  }, [city]);

  const fetchSuggestions = async (query: string) => {
    console.log('Fetching suggestions for:', query);
    try {
      const { data, error } = await supabase
        .from('cities')
        .select('combined_name')
        .ilike('combined_name', `${query}%`)
        .limit(5);

      if (error) throw error;

      console.log('Suggestions data:', data);
      setSuggestions(data.map(item => item.combined_name));
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    console.log('Suggestion clicked:', suggestion);
    setCity(suggestion);
    onSearch(suggestion);
    setSuggestions([]);
  };

  return (
    <div className="relative w-full">
      <div className="flex w-full">
        <Input
          type="text"
          placeholder="Enter city, state"
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
            if (e.target.value.length < 3) {
              setSuggestions([]);
            }
          }}
          className="bg-zinc-800 text-white border-zinc-700 flex-grow"
        />
        <Button
          onClick={(e) => {
            onSubmit(e);
            onSearch(city);
            setSuggestions([]);
          }}
          className="bg-blue-500 hover:bg-blue-600 text-white ml-2"
          disabled={isLoading}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </Button>
      </div>
      {suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-zinc-800 border border-zinc-700 rounded-b-lg shadow-lg mt-1">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-zinc-700 cursor-pointer text-white"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}