import React from 'react';
import { CalendarIcon, FileTextIcon } from "@radix-ui/react-icons";
import { BellIcon, Share2Icon } from "lucide-react";
import { cn } from "../../lib/utils";
import { Calendar } from "../ui/calendar";
import { BentoGrid, BentoCard } from '../magicui/bento-grid';
import Marquee from "../magicui/marquee";
import { AnimatedList } from "../magicui/animated-list";
import TypingAnimation from "../magicui/typing-animation";

const files = [
  {
    name: "bitcoin.pdf",
    body: "Bitcoin is a cryptocurrency invented in 2008 by an unknown person or group of people using the name Satoshi Nakamoto.",
  },
  {
    name: "finances.xlsx",
    body: "A spreadsheet or worksheet is a file made of rows and columns that help sort data, arrange data easily, and calculate numerical data.",
  },
  {
    name: "logo.svg",
    body: "Scalable Vector Graphics is an Extensible Markup Language-based vector image format for two-dimensional graphics with support for interactivity and animation.",
  },
  {
    name: "keys.gpg",
    body: "GPG keys are used to encrypt and decrypt email, files, directories, and whole disk partitions and to authenticate messages.",
  },
  {
    name: "seed.txt",
    body: "A seed phrase, seed recovery phrase or backup seed phrase is a list of words which store all the information needed to recover Bitcoin funds on-chain.",
  },
];

const Features: React.FC = () => {
  const features = [
    {
      Icon: FileTextIcon,
      name: "Save your documents",
      description: "We can keep your property documents in one place.",
      href: "#",
      cta: "Learn more",
      className: "col-span-3 lg:col-span-1 bg-zinc-800 text-white", // Updated className
      background: (
        <Marquee
          pauseOnHover
          className="absolute top-10 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_40%,#000_100%)] "
        >
          {files.map((f, idx) => (
            <figure
              key={idx}
              className={cn(
                "relative w-32 cursor-pointer overflow-hidden rounded-xl border p-4",
                "border-zinc-700 bg-zinc-800 hover:bg-zinc-700", // Updated colors
                "transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none",
              )}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-col">
                  <figcaption className="text-sm font-medium text-white">
                    {f.name}
                  </figcaption>
                </div>
              </div>
              <blockquote className="mt-2 text-xs text-zinc-300">{f.body}</blockquote>
            </figure>
          ))}
        </Marquee>
      ),
    },
    {
      Icon: BellIcon,
      name: "Notifications",
      description: "Get notified when new properties are added that meet your criteria.",
      href: "#",
      cta: "Learn more",
      className: "col-span-3 lg:col-span-2 bg-zinc-800 text-white",
      background: (
        <AnimatedList className="absolute right-2 top-4 h-[300px] w-[600px] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105">
          {[
            { city: "Miami", cashFlow: 565 },
            { city: "Fort Lauderdale", cashFlow: 880 },
            { city: "Boca Raton", cashFlow: 720 },
          ].map((item, index) => (
            <div key={index} className="text-white p-2 bg-zinc-700 mb-2 rounded flex items-center justify-between">
              <div className="flex items-center">
                <BellIcon className="w-5 h-5 mr-2 text-blue-400" />
                <span>New Property For Sale in {item.city} - ${item.cashFlow} cash flow</span>
              </div>
              <span className="text-sm text-zinc-400">• 15m ago</span>
            </div>
          ))}
        </AnimatedList>
      ),
    },
    {
      Icon: Share2Icon,
      name: "Integrations",
      description: "We integrate data from 10 sources and counting.",
      href: "#",
      cta: "Learn more",
      className: "col-span-3 lg:col-span-2 bg-zinc-800 text-white",
      background: (
        <div className="absolute inset-0 flex items-center justify-center">
          <TypingAnimation
            className="text-2xl font-bold text-white"
            text="Data from 10+ sources"
          />
        </div>
      ),
    },
    {
      Icon: CalendarIcon,
      name: "Calendar",
      description: "Use the calendar to filter your property search by listing date.",
      className: "col-span-3 lg:col-span-1 bg-zinc-800 text-white",
      href: "#",
      cta: "Learn more",
      background: (
        <Calendar
          mode="single"
          selected={new Date(2024, 8, 5)}
          className="absolute right-0 top-10 origin-top rounded-md border-zinc-700 transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_40%,#000_100%)] group-hover:scale-105"
        />
      ),
    },
  ];

  return (
    <section className="bg-zinc-900 text-white py-20 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-center">Made for modern real estate investors</h2>
        <BentoGrid className="gap-4">
          {features.map((feature, idx) => (
            <BentoCard key={idx} {...feature} />
          ))}
        </BentoGrid>
      </div>
    </section>
  );
};

export default Features;