import React, { useState, useEffect } from 'react'
import { supabase } from '../../lib/supabaseClient'
import { Button } from '../../components/ui/button'
import { User } from '@supabase/supabase-js'
import { FcGoogle } from 'react-icons/fc'

export default function Auth() {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user ?? null)
      }
    )

    return () => {
      authListener.subscription.unsubscribe()
    }
  }, [])

  const handleGoogleLogin = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.origin,
      }
    })
  }

  const handleLogout = async () => {
    await supabase.auth.signOut()
  }

  return (
    <div>
      {user ? (
        <Button 
          onClick={handleLogout}
          className="bg-white text-black hover:bg-zinc-200"
        >
          Logout
        </Button>
      ) : (
        <Button 
          onClick={handleGoogleLogin}
          className="bg-white text-black hover:bg-zinc-200 flex items-center"
        >
          Login with Google
          <FcGoogle className="ml-2 w-5 h-5" />
        </Button>
      )}
    </div>
  )
}