import React from 'react';
import { Check } from 'lucide-react';
import { Button } from '../ui/button';

const Pricing: React.FC = () => {
  const features = [
    "Lifetime access to the app",
    "Access to all future updates and new features",
    "Email support",
    "User-friendly interface",
    "Comprehensive property analysis tools and data",
  ];

  return (
    <section className="bg-zinc-800 text-white py-20 px-4">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">Get Lifetime Access for Just $79</h2>
        <p className="text-xl mb-12 text-zinc-300">One-time payment for lifetime access to all features.</p>
        <div className="bg-zinc-700 rounded-lg p-8 shadow-lg">
          <ul className="space-y-4 mb-8">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center">
                <Check className="text-green-400 mr-2" />
                <span className="text-lg">{feature}</span>
              </li>
            ))}
          </ul>
          <a href="https://buy.stripe.com/00g5mQ5Rt0end3O5kk" className="inline-block">
            <Button size="lg" className="px-8 bg-blue-600 hover:bg-blue-700 text-white">
              Purchase Now
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;