import React from 'react';
import {
  Accordion as ShadcnAccordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent
} from '../ui/accordion';

interface FAQItem {
  question: string;
  answer: string;
}

const Accordion: React.FC<{ items: FAQItem[] }> = ({ items }) => {
  return (
    <section className="bg-zinc-900 py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-center text-white">FAQ</h2>
        <ShadcnAccordion type="single" collapsible className="w-full">
          {items.map((item, index) => (
            <AccordionItem key={index} value={`item-${index}`} className="mb-4">
              <AccordionTrigger className="text-white bg-zinc-800 hover:bg-zinc-700 p-4 rounded-t-md">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="bg-zinc-800 p-4 text-zinc-300 rounded-b-md">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </ShadcnAccordion>
      </div>
    </section>
  );
};

export default Accordion;