/* eslint-disable */
import React from 'react';
import { Home } from 'lucide-react';
import { FaGithub, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Footer: React.FC = () => {
  return (
    <footer className="bg-zinc-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center text-white text-xl font-bold mb-4">
              <Home className="w-6 h-6 mr-2" />
              PropIQ
            </a>
            <p className="text-zinc-400 max-w-xs">Designed Worldwide - Find cash flowing real estate investments with PropIQ.</p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Product</h3>
              <ul className="space-y-2">
                {['Features', 'Integrations', 'Pricing', 'Changelog'].map((item) => (
                  <li key={item}><a href="#" className="text-zinc-400 hover:text-white">{item}</a></li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Company</h3>
              <ul className="space-y-2">
                {['About us', 'Blog', 'Careers', 'Customers'].map((item) => (
                  <li key={item}><a href="#" className="text-zinc-400 hover:text-white">{item}</a></li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Resources</h3>
              <ul className="space-y-2">
                {['Contact', 'DPA', 'Privacy Policy', 'Terms of service'].map((item) => (
                  <li key={item}><a href="#" className="text-zinc-400 hover:text-white">{item}</a></li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Developers</h3>
              <ul className="space-y-2">
                {['API', 'Status', 'GitHub', 'README'].map((item) => (
                  <li key={item}><a href="#" className="text-zinc-400 hover:text-white">{item}</a></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="border-t border-zinc-800 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-zinc-400 mb-4 md:mb-0">&copy; 2024 PropIQ. All rights reserved. Made with ❤️ in USA.</p>
          <div className="flex space-x-4">
            <a href="#" className="text-zinc-400 hover:text-white">
              <FaXTwitter className="w-6 h-6" />
            </a>
            <a href="#" className="text-zinc-400 hover:text-white">
              <FaGithub className="w-6 h-6" />
            </a>
            <a href="#" className="text-zinc-400 hover:text-white">
              <FaInstagram className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;