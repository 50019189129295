import React from 'react';
//import { Button } from '../ui/button';
import { Home } from 'lucide-react';
import Auth from '../../components/auth/Auth';

const Navbar: React.FC = () => {
    return (
      <div className="flex justify-center w-full bg-black py-4">
        <nav className="flex items-center justify-between px-4 py-2 bg-zinc-900 rounded-full max-w-6xl w-full">
          <div className="flex items-center space-x-6">
            <a href="/" className="flex items-center text-white text-xl font-bold">
              <Home className="w-6 h-6 mr-2" />
              PropIQ
            </a>
            <ul className="hidden md:flex space-x-6">
              {['Features', 'PropSearch', 'Company', 'Contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item.toLowerCase()}`} className="text-zinc-300 hover:text-white text-sm">{item}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center space-x-2">
            <Auth />
          </div>
        </nav>
      </div>
    );
  };
  
  export default Navbar;
