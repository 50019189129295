import React from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';


const Signup: React.FC = () => {
    return (
      <section className="bg-zinc-900 text-white py-20 px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Start investing smarter today</h2>
          <p className="text-xl mb-8 text-zinc-300">
            Join thousands of investors using our platform to find and manage profitable real estate investments.
          </p>
          <form className="flex flex-col sm:flex-row gap-4 justify-center">
            <Input
              type="email"
              placeholder="Enter your email"
              className="bg-zinc-800 border-zinc-700 text-white placeholder-zinc-500"
            />
            <Button type="submit" size="lg" className="bg-blue-600 hover:bg-blue-700 text-white">
              Get Started
            </Button>
          </form>
        </div>
      </section>
    );
  };
  
  export default Signup;
